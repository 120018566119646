import { WppIconArrow, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import WhiteButton from 'pages/customProcess/components/ikeaNew/components/button/whiteButton/WhiteButton'
import styles from 'pages/customProcess/components/ikeaNew/components/phaseCard/overlay/Overlay.module.scss'
import { Pill } from 'pages/customProcess/components/ikeaNew/components/pill/Pill'
import { PhaseData } from 'pages/customProcess/components/ikeaNew/types'

interface Props {
  phaseData: PhaseData
  isHover: boolean
  backgroundColor: string
}

const Overlay = ({ phaseData, isHover, backgroundColor }: Props) => {
  const isDarkText = useMemo(() => backgroundColor !== '#0058AB', [backgroundColor])

  return (
    <Flex
      style={{ backgroundColor }}
      className={clsx(styles.overlayContainer, { [styles.active]: isHover })}
      direction="column"
      gap={16}
    >
      <div className={styles.titleContainer}>
        <WppTypography
          className={clsx(styles.title, { [styles.blackText]: isDarkText }, { [styles.hover]: isHover })}
          type="s-body"
          tag="span"
        >
          {phaseData.title}
        </WppTypography>
      </div>
      <Flex direction="column" gap={16} className={clsx(styles.descriptionContainer, { [styles.show]: isHover })}>
        <WppTypography
          className={clsx(styles.description, { [styles.blackText]: isDarkText })}
          type="s-body"
          tag="span"
        >
          {phaseData.description}
        </WppTypography>
        <WhiteButton
          title="Learn more"
          onClick={event => {
            event.preventDefault()
          }}
        />
      </Flex>
      <Flex className={styles.iconButtonContainer} justify="between">
        <WppIconArrow direction={isHover ? 'top' : 'right'} color={isDarkText ? '#000000' : '#ffffff'} width={16} />
        <Flex className={clsx(styles.buttonContainer, { [styles.show]: isHover })} gap={8}>
          {phaseData.pills.map((pill, index) => (
            <Pill key={index} title={pill} variant={isDarkText ? 'black' : 'white'} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Overlay
