import { WppIconArrow, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import WhiteButton from 'pages/customProcess/components/ikeaNew/components/button/whiteButton/WhiteButton'
import styles from 'pages/customProcess/components/ikeaNew/components/phaseCard/descriptionCard/DescriptionCard.module.scss'
import { PhaseData } from 'pages/customProcess/components/ikeaNew/types'
import { routesManager } from 'utils/routesManager'

interface Props {
  phaseData: PhaseData
  className?: string
  isOpen: boolean
  displayTop: boolean
  transitionDirection: 'up' | 'down'
  onDescriptionCardEnter: () => void
  onDescriptionCardLeave: () => void
}

const DescriptionCard = ({
  phaseData,
  className,
  isOpen,
  displayTop,
  transitionDirection,
  onDescriptionCardEnter,
  onDescriptionCardLeave,
}: Props) => {
  const isDarkText = useMemo(() => phaseData.color !== '#0058AB', [phaseData.color])

  return (
    <Flex
      gap={16}
      direction="column"
      style={{ backgroundColor: phaseData.color }}
      className={clsx(
        styles.container,
        { className },
        { [styles.displayTop]: displayTop },
        { [styles.show]: isOpen },
        { [styles.transitionUp]: transitionDirection === 'up' },
        { [styles.transitionDown]: transitionDirection === 'down' },
      )}
      onMouseEnter={onDescriptionCardEnter}
      onMouseLeave={onDescriptionCardLeave}
    >
      <WppTypography type="l-body" tag="span" className={clsx(styles.title, { [styles.blackText]: isDarkText })}>
        {phaseData.title}
      </WppTypography>
      <WppTypography
        type="l-body"
        tag="span"
        className={clsx(styles.shortDescription, { [styles.blackText]: isDarkText })}
      >
        {phaseData.description}
      </WppTypography>
      <Flex direction="column" gap={8}>
        <WppTypography type="l-body" tag="span" className={clsx(styles.subtitle, { [styles.blackText]: isDarkText })}>
          Considerations
        </WppTypography>
        <Flex direction="column" gap={4}>
          {phaseData.moreInfo.considerations.map((consideration, index) => (
            <Flex gap={8} key={index}>
              <WppIconArrow width={16} color={isDarkText ? '#000000' : '#ffffff'} />
              <WppTypography type="l-body" tag="span" className={clsx(styles.text, { [styles.blackText]: isDarkText })}>
                {consideration}
              </WppTypography>
            </Flex>
          ))}
        </Flex>
        <WppTypography type="l-body" tag="span" className={clsx(styles.subtitle, { [styles.blackText]: isDarkText })}>
          Outcomes
        </WppTypography>
        <Flex direction="column" gap={4}>
          {phaseData.moreInfo.outcomes.map((outcomes, index) => (
            <Flex direction="row" key={index}>
              <WppIconArrow width={16} color={isDarkText ? '#000000' : '#ffffff'} className={styles.arrow} />
              <WppTypography type="l-body" tag="span" className={clsx(styles.text, { [styles.blackText]: isDarkText })}>
                {outcomes}
              </WppTypography>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Link to={routesManager.systemUrls.orchestration.projects.url} className={styles['go-to-project-button']}>
        <WhiteButton title="Go to project" />
      </Link>
    </Flex>
  )
}

export default DescriptionCard
