import { WppTypography, WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { getPreviewImageUrl } from 'pages/phaseBuilder/utils'
import styles from 'pages/processBuilder/templates/linear1/phase/Phase.module.scss'
import { FormPhaseWithIndex } from 'types/process/process'

interface Props {
  pageWidth: number | undefined
  numberOfAllPhases: number
  phase: FormPhaseWithIndex
  index: number
  isActive: boolean
  isAnyActive: boolean
  isContainedImage?: boolean
  onPhaseClick: (index: number) => void
}

export const Phase = ({
  pageWidth,
  numberOfAllPhases,
  phase,
  isActive,
  isAnyActive,
  isContainedImage = false,
  onPhaseClick,
}: Props) => {
  const url = phase.innerPageEnabled && phase?.id ? `./phase/${phase?.id}` : phase?.url || ''
  const ACTIVE_PHASE_WIDTH = 490
  const PHASE_GAP = 4

  const phaseWidths = useMemo(() => {
    if (!pageWidth) {
      return
    }

    return {
      phase: pageWidth / numberOfAllPhases - PHASE_GAP,
      activePhase: ACTIVE_PHASE_WIDTH - PHASE_GAP,
      inactivePhase: (pageWidth - ACTIVE_PHASE_WIDTH) / (numberOfAllPhases - 1) - PHASE_GAP,
    }
  }, [pageWidth, numberOfAllPhases])

  const imgUrl = useMemo(
    () => (phase?.image && phase?.image.length ? `url(${getPreviewImageUrl(phase?.image)}` : ''),
    [phase?.image],
  )

  return (
    <Link
      to={isActive && url ? url : ''}
      className={clsx(styles.phase, isActive && styles.activePhase)}
      style={{
        width: phaseWidths?.[isAnyActive ? (isActive ? 'activePhase' : 'inactivePhase') : 'phase'],
        backgroundColor: !isActive ? phase?.colors?.[0] : phase?.colors?.[1],
        backgroundImage: !isContainedImage ? imgUrl : '',
      }}
      onClick={() => onPhaseClick(phase.phaseIndex)}
    >
      <Flex className={styles.fullheight}>
        <div
          className={clsx(styles.fullWidth, styles.cardPadding, {
            [styles.w50]: imgUrl && isActive && isContainedImage,
            [styles.cardPaddingActive]: isActive && !imgUrl,
          })}
        >
          <WppTypography
            className={clsx(
              imgUrl ? styles.phaseIndex : styles.phaseIndexRotated,
              isActive && imgUrl && styles.phaseIndexActive,
            )}
            style={isActive ? { color: phase?.colors?.[3] } : { color: phase?.colors?.[2] }}
          >
            {phase.phaseIndex}
          </WppTypography>
          <Flex direction="column" justify="end" className={styles.fullheight}>
            {isActive ? (
              <div>
                <WppTypography
                  type="4xl-display"
                  className={styles.activePhaseTitle}
                  style={{ color: phase?.colors?.[5] }}
                >
                  {phase.title}
                </WppTypography>
                <div>
                  <WppTypography type="s-body" className={styles.description} style={{ color: phase?.colors?.[6] }}>
                    {phase.description}
                  </WppTypography>
                </div>
              </div>
            ) : (
              <Flex direction="column" justify="end" className={styles.fullheight}>
                <Flex justify="between" align="end" gap={4} className={styles.fullheight}>
                  <WppTypography type="2xl-heading" className={styles.phaseTitle} style={{ color: phase?.colors?.[4] }}>
                    {phase.title}
                  </WppTypography>
                  <Flex
                    justify="center"
                    align="center"
                    className={styles.iconButton}
                    style={{ backgroundColor: phase?.colors?.[7], borderColor: phase?.colors?.[9] }}
                  >
                    <WppIconArrow direction="right" color={phase?.colors?.[8] ?? 'var(--wpp-grey-color-000)'} />
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </div>

        {imgUrl && isActive && isContainedImage && (
          <div className={styles.phaseImg} style={{ backgroundImage: imgUrl }} />
        )}
      </Flex>
    </Link>
  )
}
