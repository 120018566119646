import campaignImgSrc from 'pages/customWidget/ikea/assets/campaign.png'
import marketingImgSrc from 'pages/customWidget/ikea/assets/marketing.png'
import opitimisationImgSrc from 'pages/customWidget/ikea/assets/optimisation.png'
import { PhaseData, Phases } from 'pages/customWidget/ikea/types'

interface IkeaProcessWidgetDataInterface {
  title: string
  phases: PhaseData[]
}

export const IkeaProcessWidgetData: IkeaProcessWidgetDataInterface = {
  title: 'Accelerated Growth Planning Process',
  phases: [
    {
      phaseId: Phases.Marketing,
      title: 'Marketing Cycle',
      bgColor: '#0058AB',
      color: '#FFFFFF',
      description: "Identifying the opportunity for growing IKEA'S process",
      img: marketingImgSrc,
      moreInfo: {
        considerations: ['Opportunity Definition', 'Audience Analysis', 'Strategy & Management Solutions'],
      },
    },
    {
      phaseId: Phases.Campaign,
      title: 'Campaign Cycle',
      bgColor: '#FBD915',
      color: '#000000',
      description: 'Designing the communications systems to deliver to the identified opportunity',
      img: campaignImgSrc,
      moreInfo: {
        considerations: ['Channel Allocation', 'Execution Plan'],
      },
    },
    {
      phaseId: Phases.Optimisation,
      title: 'Optimisation Cycle',
      bgColor: '#DEBD9C',
      color: '#000000',
      description: 'The test, learn and in-flight optimisation plan',
      img: opitimisationImgSrc,
      moreInfo: {
        considerations: ['Reporting, Optimisation & Measurement'],
      },
    },
  ],
}
