import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    title: false,
    description: false,
    template: true,
    background: true,
    positionBackground: true,
    logo: true,
    widgetBackgroundImage: true,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
    },
    groups: {
      title: true,
      description: true,
      phasesLocalIds: true,
      colors: true,
    },
  },
  colors: {
    page: {
      titles: [
        'Page background',
        'Widget background',
        'Widget logo background',
        'Widget gradient',
        'Group info divider',
      ],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: ['Point', 'Point border', 'Title', 'Overlay color', 'Overlay text', 'Popup text'],
      defaultValues: [],
    },
    group: {
      titles: ['Title', 'Description Title', 'Description', 'Group gradient'],
      defaultValues: [],
    },
  },
}
