import { Circular1Process } from 'pages/processBuilder/templates/circular1/Circular1Process'
import { templateConfig as circular1Config } from 'pages/processBuilder/templates/circular1/templateConfig'
import { Circular2Process } from 'pages/processBuilder/templates/circular2/Circular2Process'
import { templateConfig as circular2Config } from 'pages/processBuilder/templates/circular2/templateConfig'
import { Circular3Process } from 'pages/processBuilder/templates/circular3/Circular3Process'
import { templateConfig as circular3Config } from 'pages/processBuilder/templates/circular3/templateConfig'
import { Circular4Process } from 'pages/processBuilder/templates/circular4/Circular4Process'
import { templateConfig as circular4Config } from 'pages/processBuilder/templates/circular4/templateConfig'
import { Circular5Process } from 'pages/processBuilder/templates/circular5/Circular5Process'
import { templateConfig as circular5Config } from 'pages/processBuilder/templates/circular5/templateConfig'
import { LinearProcess1 } from 'pages/processBuilder/templates/linear1/LinearProcess1'
import { templateConfig as linear1Config } from 'pages/processBuilder/templates/linear1/templateConfig'
import { Templates } from 'types/process/process'

export const templates = {
  [Templates.Circular1]: Circular1Process,
  [Templates.Circular2]: Circular2Process,
  [Templates.Circular3]: Circular3Process,
  [Templates.Circular4]: Circular4Process,
  [Templates.Circular5]: Circular5Process,
  [Templates.Linear1]: LinearProcess1,
}

export const templateConfigs = {
  [Templates.Circular1]: circular1Config,
  [Templates.Circular2]: circular2Config,
  [Templates.Circular3]: circular3Config,
  [Templates.Circular4]: circular4Config,
  [Templates.Circular5]: circular5Config,
  [Templates.Linear1]: linear1Config,
}
