import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logo: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
    },
    groups: false,
  },
  colors: {
    page: {
      titles: ['Page background', 'SVG Border', 'Logo background'],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: [
        'Description text',
        'Phase title',
        'Tools text',
        'Phase background',
        'Subphase 1 background',
        'Subphase 2 background',
        'Subphase 3 background',
      ],
      defaultValues: [],
    },
    group: {
      titles: [],
      defaultValues: [],
    },
  },
}
