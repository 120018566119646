export enum Phases {
  Marketing = 'Marketing',
  Campaign = 'Campaign',
  Optimisation = 'Optimisation',
}

export interface PhaseData {
  phaseId: Phases
  title: string
  description: string
  img: string
  color: string
  bgColor: string
  moreInfo: {
    considerations: string[]
  }
}
