import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular1/Circular1Process.module.scss'
import { Phase } from 'pages/processBuilder/templates/circular1/phase/Phase'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const Circular1Svg = ({
  activePhase,
  className,
  blurClassname,
  onPhaseEnter,
  onPhaseLeave,
  config,
  isEditMode,
}: {
  activePhase: MayBeNull<number>
  className?: string
  blurClassname?: string
  onPhaseEnter: (phase: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
  isEditMode: boolean
}) => {
  const isActivePhase = useCallback((phase: number) => phase === activePhase, [activePhase])
  const widgetColor = config.colors?.[1] || 'white'
  const parrentTitle = useMemo(
    () => (typeof activePhase === 'number' ? config.phases?.[activePhase]?.groupTitle : ''),
    [activePhase, config.phases],
  )
  const phaseSize = useMemo(() => (config?.phases.length <= 3 ? 120 : 90), [config?.phases.length])

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <foreignObject className={blurClassname} />
      <g transform="matrix(1, 0, 0, 1, -1.5, 67)">
        <g filter="url(#filter0_bd_138_22403)" className={styles.fade1}>
          <circle cx="641" cy="385.027" r="300" fill={widgetColor} fillOpacity="0.1" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter1_bd_138_22403)" className={styles.fade2}>
          <circle cx="641" cy="385.027" r="258" fill={widgetColor} fillOpacity="0.15" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter2_bd_138_22403)" className={styles.fade3}>
          <circle cx="641" cy="385.027" r="218" fill={widgetColor} fillOpacity="0.2" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter3_d_138_22403)" className={styles.fade4}>
          <circle cx="641" cy="385.027" r="180" fill={widgetColor} fillOpacity="0.25" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter4_d_138_22403)" className={styles.fade5}>
          <circle cx="641" cy="385.027" r="140" fill={widgetColor} fillOpacity="0.3" shapeRendering="crispEdges" />
        </g>

        {config?.phases?.map((item, index) => (
          <circle
            cx="642"
            cy="93"
            r={15}
            fill={item?.colors?.[1] || 'white'}
            transform={`rotate(${config?.phases ? (360 / config?.phases.length) * index : 0} 641 385) translate(0, -8)`}
          />
        ))}

        {config?.phases?.map((item, index) => (
          <Phase
            key={index}
            onMouseEnter={onPhaseEnter(index)}
            onMouseLeave={onPhaseLeave}
            isActive={isActivePhase(index)}
            rotation={config?.phases ? (360 / config?.phases.length) * index : 0}
            size={phaseSize}
            hoverSize={360 / config?.phases.length}
            phase={item}
            isEditMode={isEditMode}
          />
        ))}

        <g filter="url(#filter4_d_138_22403)" className={styles.fade6}>
          <path
            d="M746 385C746 443.54 698.556 491 640.01 491C581.464 491 534 443.54 534 385C534 326.459 581.464 279 640.01 279C698.556 279 746 326.459 746 385Z"
            fill={widgetColor}
          />
        </g>

        <foreignObject
          width={212}
          height={212}
          x={534}
          y={279.027}
          className={clsx(styles.logoWrap, styles.fade6)}
          style={{
            background:
              (typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[4]) || config?.colors?.[2],
          }}
        >
          <WppTypography
            type="xl-heading"
            className={styles.phaseGroupTitle}
            style={{
              opacity: parrentTitle ? 1 : 0,
              color: typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[5],
            }}
          >
            {parrentTitle}
          </WppTypography>
          <img
            src={getPreviewImageUrl(config?.logo)}
            alt=""
            className={styles.logo}
            style={{ opacity: parrentTitle ? 0 : 1 }}
          />
        </foreignObject>
      </g>
      <defs>
        <radialGradient
          id="toolsLine"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(645.224 145.289) rotate(91.1259) scale(249.088 119.047)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
