// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nEudz{flex-wrap:wrap;padding:5px;border:1px solid var(--wpp-grey-color-500);border-radius:8px}.cXa3h{width:30px;height:30px;border:1px solid var(--wpp-grey-color-300);border-radius:3px}.cXa3h::part(button){width:30px;height:30px}.DPtJ2{position:absolute;margin-top:3px}.DPtJ2::part(button){background-color:var(--wpp-white-color);box-shadow:rgba(0,0,0,.15) 0 2px 4px}", "",{"version":3,"sources":["webpack://./src/components/form/formPalette/FormPalette.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,WAAA,CACA,0CAAA,CACA,iBAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,0CAAA,CACA,iBAAA,CAEA,qBACE,UAAA,CACA,WAAA,CAIJ,OACE,iBAAA,CACA,cAAA,CAEA,qBACE,uCAAA,CACA,oCAAA","sourcesContent":[".root {\n  flex-wrap: wrap;\n  padding: 5px;\n  border: 1px solid var(--wpp-grey-color-500);\n  border-radius: 8px;\n}\n\n.colorItem {\n  width: 30px;\n  height: 30px;\n  border: 1px solid var(--wpp-grey-color-300);\n  border-radius: 3px;\n\n  &::part(button) {\n    width: 30px;\n    height: 30px;\n  }\n}\n\n.removeBtn {\n  position: absolute;\n  margin-top: 3px;\n\n  &::part(button) {\n    background-color: var(--wpp-white-color);\n    box-shadow: rgb(0 0 0 / 15%) 0 2px 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "nEudz",
	"colorItem": "cXa3h",
	"removeBtn": "DPtJ2"
};
export default ___CSS_LOADER_EXPORT___;
