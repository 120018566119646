import { WppPopover, WppActionButton, WppIconAddCircle, WppIconTrash } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback, forwardRef, ComponentPropsWithoutRef } from 'react'
import ColorPicker from 'react-best-gradient-color-picker'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formPalette/FormPalette.module.scss'
import { useField } from 'hooks/form/useField'

export interface FormTextPaletteProps extends ComponentPropsWithoutRef<typeof Flex> {
  name: string
  hideAdd?: boolean
  hideRemove?: boolean
  titles?: string[]
  className?: string
}

export const FormPalette = forwardRef<HTMLDivElement, FormTextPaletteProps>(
  ({ name, hideAdd = false, hideRemove = false, titles, className }, ref) => {
    const {
      field: { value: colors = [], onChange: setColors },
    } = useField({
      name,
    })

    const [activeColor, setActiveColor] = useState<number>()

    const addColor = useCallback(() => {
      setColors([...colors, 'red'])
    }, [colors, setColors])

    const removeColor = useCallback(() => {
      const updatedColors = colors.filter((color: string, colorIndex: number) => colorIndex !== activeColor)
      setColors(updatedColors)
    }, [colors, setColors, activeColor])

    const handleColorClick = useCallback(
      (index: number) => () => {
        setActiveColor(index)
      },
      [],
    )

    const updateActiveColor = useCallback(
      (color: string) => {
        const updatedColors = colors?.map((item: string, index: number) => (index === activeColor ? color : item))
        setColors(updatedColors)
      },
      [activeColor, colors, setColors],
    )

    return (
      <Flex className={clsx(styles.root, className)} gap={10} align="center" ref={ref}>
        {colors?.map((color: string, index: number) => (
          <WppPopover key={index}>
            <WppActionButton
              className={styles.colorItem}
              style={{ background: color }}
              onClick={handleColorClick(index)}
              title={titles?.[index] || ''}
              slot="trigger-element"
            />
            <div>
              <ColorPicker
                onChange={updateActiveColor}
                value={color}
                className=""
                hideColorTypeBtns={false}
                hideAdvancedSliders
                hideColorGuide
                hideInputType
                hidePresets
              />
              {!hideRemove && (
                <WppActionButton onClick={removeColor} className={styles.removeBtn}>
                  Remove
                  <WppIconTrash slot="icon-end" />
                </WppActionButton>
              )}
            </div>
          </WppPopover>
        ))}

        {!hideAdd && (
          <WppActionButton onClick={addColor}>
            <WppIconAddCircle />
          </WppActionButton>
        )}
      </Flex>
    )
  },
)
