import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logo: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: true,
      url: true,
    },
    groups: false,
  },
  colors: {
    page: {
      titles: ['Page background', 'Widget background', 'Widget logo background'],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: ['Overlay background', 'Point', 'Title & descr.', 'Overlay text', 'Logo background', 'Group title'],
      defaultValues: [],
    },
    group: {
      titles: [],
      defaultValues: [],
    },
  },
}
