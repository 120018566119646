import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular3/Circular3Process.module.scss'
import { Phase } from 'pages/processBuilder/templates/circular3/phase/Phase'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const Circular3Svg = ({
  activePhase,
  onPhaseEnter,
  onPhaseLeave,
  config,
}: {
  activePhase: MayBeNull<number>
  onPhaseEnter: (phase: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
}) => {
  const isActivePhase = useCallback((phase: number) => phase === activePhase, [activePhase])
  const parrentTitle = useMemo(
    () => (typeof activePhase === 'number' ? config.phases?.[activePhase]?.groupTitle : ''),
    [activePhase, config.phases],
  )
  const phaseSize = config.phases.length > 2 ? 360 / config.phases.length : 120

  const getPhaseRotation = useCallback(
    (index: number) => {
      const isShiffted = config.phases.length > 2
      const rotateStep = 360 / config.phases.length

      return isShiffted ? rotateStep * index + rotateStep / 2 : rotateStep * index
    },
    [config.phases.length],
  )

  const widgetBg =
    (config.widgetBackgroundImage?.[0]
      ? `url(${getPreviewImageUrl(config.widgetBackgroundImage)})`
      : config.colors?.[1]) || '#A9BDFD'

  const borderColor = useMemo(() => {
    const phaseColors = config?.phases.reduce((accum: string[], item) => {
      item?.colors?.[5] && accum.push(item?.colors?.[5])
      return accum
    }, [])

    if (phaseColors.length) {
      const colorShift = 100 / phaseColors.length / 2
      phaseColors.push(phaseColors[0])
      phaseColors[0] += ` ${colorShift}%`
      phaseColors[phaseColors.length - 2] += ` ${100 - colorShift}%`
    }

    return (phaseColors.length ? `conic-gradient(${phaseColors.join()})` : config.colors?.[3]) || 'gray'
  }, [config])

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <foreignObject
        className={styles.widgetCircle}
        width={626}
        height={626}
        x={326}
        y={139}
        style={{ background: widgetBg }}
      />

      <foreignObject
        className={styles.widgetCircle}
        width={634}
        height={634}
        x={324}
        y={135}
        clipPath="url(#cut-off-bottom)"
        style={{ background: borderColor }}
        transform={`rotate(${config.phases.length > 2 ? 0 : -70} 641 452)`}
      />

      <g transform="matrix(1, 0, 0, 1, -1.5, 67)">
        {config?.phases?.map((item, index) => (
          <Phase
            key={index}
            onMouseEnter={onPhaseEnter(index)}
            onMouseLeave={onPhaseLeave}
            isActive={isActivePhase(index)}
            activePhase={activePhase}
            rotation={getPhaseRotation(index)}
            phase={item}
            size={phaseSize}
          />
        ))}

        <foreignObject
          width={212}
          height={212}
          x={534}
          y={279.027}
          className={styles.logoWrap}
          style={{
            background:
              (typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[6]) || config?.colors?.[2],
          }}
        >
          <WppTypography
            type="xl-heading"
            className={styles.phaseGroupTitle}
            style={{
              opacity: parrentTitle ? 1 : 0,
              color: typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[7],
            }}
          >
            {parrentTitle}
          </WppTypography>
          <img
            src={getPreviewImageUrl(config?.logo)}
            alt=""
            className={styles.logo}
            style={{ opacity: parrentTitle ? 0 : 1 }}
          />
        </foreignObject>
      </g>

      <defs>
        <clipPath id="cut-off-bottom">
          <path
            d="M 852.635 451.516 C 852.635 537.979 817.463 616.544 760.813 673.194 C 704.163 729.844 625.598 765.016 539.135 765.016 C 452.672 765.016 374.107 729.844 317.457 673.194 C 260.807 616.544 225.635 537.979 225.635 451.516 C 225.635 365.053 260.807 286.488 317.457 229.838 C 374.107 173.188 452.672 138.016 539.135 138.016 C 625.598 138.016 704.163 173.188 760.813 229.838 C 817.463 286.488 852.635 365.053 852.635 451.516 Z M 758.692 231.959 C 702.42 175.687 624.985 141.016 539.135 141.016 C 453.285 141.016 375.85 175.687 319.578 231.959 C 263.306 288.231 228.635 365.666 228.635 451.516 C 228.635 537.366 263.306 614.801 319.578 671.073 C 375.85 727.345 453.285 762.016 539.135 762.016 C 624.985 762.016 702.42 727.345 758.692 671.073 C 814.964 614.801 849.635 537.366 849.635 451.516 C 849.635 365.666 814.964 288.231 758.692 231.959 Z"
            fill="red"
            transform="translate(100 0)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
