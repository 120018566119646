import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: true,
    description: true,
    background: true,
    positionBackground: true,
    logo: false,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: false,
      image: true,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
    },
    groups: {
      title: true,
      description: true,
      phasesLocalIds: true,
      colors: true,
    },
  },
  colors: {
    page: {
      titles: ['Page background', 'Title', 'Description'],
      defaultValues: [],
    },
    button: {
      titles: ['Background', 'Text'],
      defaultValues: [],
    },
    phase: {
      titles: [
        'Background',
        'Active background',
        'Index text',
        'Active index text',
        'Title',
        'Active Title',
        'Description',
        'Button background',
        'Button icon',
        'Button border',
      ],
      defaultValues: [],
    },
    group: {
      titles: ['Border', 'Title', 'Description'],
      defaultValues: [],
    },
  },
}
